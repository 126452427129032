import Badge from 'react-bootstrap/Badge';

const Status = (prop) => {
  const { state } = prop;

  const statusBg = {
    done: 'success',
    killed: 'secondary',
    submitted: 'warning',
    running: 'info',
    failed: 'danger',
  };

  return (
    <Badge pill bg={statusBg[state]} className="status-badge">
      {state}
    </Badge>
  );
};

export default Status;
