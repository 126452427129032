import { React, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Col, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { L2ADateCheck, OrthoFileCheck, createJob, DRfilesCheck } from './api';

import { DeepResolver, Inference, Ortho } from './JobTemplates';
import {
  DRV2,
  DRV3,
  DRV2_TASK_CHAINING,
  INFERENCE,
  INFERENCE_CPU,
  ORTHO,
} from '../../constants/jobType';
import jobSchema from '../../utils/validationSchema';
import RetryCancelModal from './Modals/RetryModal';
import OrthoPrepModal from './Modals/OrthoPrepModal';

const CreateJob = ({
  onSuccess,
  onError,
  onNotFoundError,
  onOrthoMoreError,
  onOrthoLessError,
  onOrthoError,
  onOrthoOldFileError,
  onOrthoVersionFoundError,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [skipL2ACheck, setSkipL2ACheck] = useState(false);
  const [retryClicked, setRetryClicked] = useState(false);
  const [showRetryModal, setShowRetryModal] = useState(false);
  const [additionalParams, setAdditionalParams] = useState({});
  const [showOrthoPrepModal, setShowOrthoPrepModal] = useState(false);
  const [params, setParams] = useState({});

  const formik = useFormik({
    initialValues: {
      jobParams: {},
      jobType: '',
    },
    validationSchema: jobSchema,
    onSubmit: async (values) => {
      setLoading(true);

      switch (values.jobType) {
        // case DRV2:
        case DRV3: {
          if (retryClicked && skipL2ACheck) {
            // eslint-disable-next-line no-use-before-define
            createJobMutation.mutate(values);
          } else {
            const l2aDateValidation = await L2ADateCheck(
              values.jobParams.date,
              values.jobParams.mgrs
            );

            if (!l2aDateValidation && !skipL2ACheck) {
              // onL2AError();
              setLoading(false);
              setShowRetryModal(true);
            } else {
              // eslint-disable-next-line no-use-before-define
              createJobMutation.mutate(values);
            }
          }

          break;
        }
        case INFERENCE: {
          // eslint-disable-next-line no-use-before-define
          // createJobMutation.mutate(values);
          try {
            const orthofileCheck = await OrthoFileCheck(
              values.jobParams.country,
              values.jobParams.tile,
              values.jobParams.DR_TILES_VER
            );

            const drfileCheck = await DRfilesCheck(
              values.jobParams.country,
              values.jobParams.tile
            );

            console.log('drfilecheck---------', drfileCheck);

            if (
              orthofileCheck.file_check === 'False' &&
              orthofileCheck.meta === 'less than 225'
            ) {
              setShowOrthoPrepModal(true);
              setParams({ ...values.jobParams });
            } else if (
              orthofileCheck.file_check === 'False' &&
              orthofileCheck.meta === 'more than 225'
            ) {
              onOrthoMoreError(orthofileCheck);
              setLoading(false);
            } else if (
              orthofileCheck.file_check === 'False' &&
              orthofileCheck.meta === 'no files'
            ) {
              if (drfileCheck.files === 0) {
                onOrthoError(orthofileCheck);
                setLoading(false);
              } else {
                setShowOrthoPrepModal(true);
                setParams({ ...values.jobParams });
              }

              // setLoading(false);
            } else if (
              orthofileCheck.file_check === 'False' &&
              orthofileCheck.meta === 'old ortho-prep'
            ) {
              onOrthoOldFileError(orthofileCheck);
              setLoading(false);
            } else {
              // eslint-disable-next-line no-use-before-define
              // createJobMutation.mutate(values);
              setShowOrthoPrepModal(true);
              setParams({ ...values.jobParams });
            }
          } catch (e) {
            // console.error('Error in OrthoFileCheck:', e.response.status);

            if (e.response.status === 404) {
              onNotFoundError();
            } else {
              onOrthoVersionFoundError();
            }

            setLoading(false);
          }

          break;
        }
        case INFERENCE_CPU: {
          // eslint-disable-next-line no-use-before-define
          // createJobMutation.mutate(values);
          try {
            const orthofileCheck = await OrthoFileCheck(
              values.jobParams.country,
              values.jobParams.tile,
              values.jobParams.DR_TILES_VER
            );

            const drfileCheck = await DRfilesCheck(
              values.jobParams.country,
              values.jobParams.tile
            );

            console.log('drfilecheck---------', drfileCheck);

            if (
              orthofileCheck.file_check === 'False' &&
              orthofileCheck.meta === 'less than 225'
            ) {
              setShowOrthoPrepModal(true);
              setParams({ ...values.jobParams });
            } else if (
              orthofileCheck.file_check === 'False' &&
              orthofileCheck.meta === 'more than 225'
            ) {
              onOrthoMoreError(orthofileCheck);
              setLoading(false);
            } else if (
              orthofileCheck.file_check === 'False' &&
              orthofileCheck.meta === 'no files'
            ) {
              if (drfileCheck.files === 0) {
                onOrthoError(orthofileCheck);
                setLoading(false);
              } else {
                setShowOrthoPrepModal(true);
                setParams({ ...values.jobParams });
              }

              // setLoading(false);
            } else if (
              orthofileCheck.file_check === 'False' &&
              orthofileCheck.meta === 'old ortho-prep'
            ) {
              onOrthoOldFileError(orthofileCheck);
              setLoading(false);
            } else {
              // eslint-disable-next-line no-use-before-define
              // createJobMutation.mutate(values);
              setShowOrthoPrepModal(true);
              setParams({ ...values.jobParams });
            }
          } catch (e) {
            // console.error('Error in OrthoFileCheck:', e.response.status);

            if (e.response.status === 404) {
              onNotFoundError();
            } else {
              onOrthoVersionFoundError();
            }

            setLoading(false);
          }

          break;
        }
        case ORTHO: {
          // eslint-disable-next-line no-use-before-define
          // createJobMutation.mutate(values);
          try {
            const orthofileCheck = await OrthoFileCheck(
              values.jobParams.country,
              values.jobParams.tile,
              values.jobParams.DR_TILES_VER
            );

            const drfileCheck = await DRfilesCheck(
              values.jobParams.country,
              values.jobParams.tile
            );

            console.log('drfilecheck---------', drfileCheck);

            if (
              orthofileCheck.file_check === 'False' &&
              orthofileCheck.meta === 'less than 225'
            ) {
              setShowOrthoPrepModal(true);
              setParams({ ...values.jobParams });
            } else if (
              orthofileCheck.file_check === 'False' &&
              orthofileCheck.meta === 'more than 225'
            ) {
              onOrthoMoreError(orthofileCheck);
              setLoading(false);
            } else if (
              orthofileCheck.file_check === 'False' &&
              orthofileCheck.meta === 'no files'
            ) {
              if (drfileCheck.files === 0) {
                onOrthoError(orthofileCheck);
                setLoading(false);
              } else {
                setShowOrthoPrepModal(true);
                setParams({ ...values.jobParams });
              }

              // setLoading(false);
            } else if (
              orthofileCheck.file_check === 'False' &&
              orthofileCheck.meta === 'old ortho-prep'
            ) {
              onOrthoOldFileError(orthofileCheck);
              setLoading(false);
            } else {
              // eslint-disable-next-line no-use-before-define
              // createJobMutation.mutate(values);
              setShowOrthoPrepModal(true);
              setParams({ ...values.jobParams });
            }
          } catch (e) {
            // console.error('Error in OrthoFileCheck:', e.response.status);

            if (e.response.status === 404) {
              onNotFoundError();
            } else {
              onOrthoVersionFoundError();
            }

            setLoading(false);
          }

          break;
        }
        default:
          break;
      }
    },
  });

  const handleRetryJob = () => {
    setLoading(false);
    setSkipL2ACheck(true);
    setRetryClicked(true);
    setShowRetryModal(false); // Close the modal
    // eslint-disable-next-line no-use-before-define
    createJobMutation.mutate(formik.values); // Retry the job
  };

  const handleCancelRetry = () => {
    setShowRetryModal(false); // Close the modal
    // Optionally, you can perform additional actions here if needed
  };

  const createJobMutation = useMutation(createJob, {
    onSuccess: () => {
      setLoading(false);
      formik.resetForm();
      setRetryClicked(false); // Reset retryClicked after successful submission
      setSkipL2ACheck(false); // Reset skipL2ACheck after successful submission
      setShowOrthoPrepModal(false);
      onSuccess();
    },
    onError: () => {
      setLoading(false);
      // setRetryClicked(false); // Reset retryClicked after an error
      // setShowRetryModal(true);
      onError();
    },
  });

  const isSubmitButtonDisable = !(formik.dirty && formik.isValid) || isLoading;
  const jobSelectionTemplate = {
    '': { text: '----' },
    [DRV3]: {
      text: 'DRV3',
      template: <DeepResolver formik={formik} />,
    },
    [INFERENCE]: {
      text: 'INFERENCE',
      template: <Inference formik={formik} />,
    },
    [INFERENCE_CPU]: {
      text: 'INFERENCE_CPU',
      template: <Inference formik={formik} />,
    },
    [ORTHO]: {
      text: 'ORTHO',
      template: <Ortho formik={formik} />,
    },
    // [DRV2_TASK_CHAINING]: {
    //   text: 'DRV2 Task Chaining',
    //   template: <DeepResolver formik={formik} />,
    // },
  };

  const handleCancelOrthoPrep = () => {
    setShowOrthoPrepModal(false);
    // Optionally, you can perform additional actions here if needed
  };

  const handleOrthoPrepSubmit = (orthoPrepValues) => {
    // Merge additionalParams with the existing jobParams
    const updatedJobParams = {
      ...formik.values.jobParams,
      ...orthoPrepValues,
    };

    // Update the additionalParams state
    setAdditionalParams(updatedJobParams);

    formik.setFieldValue('jobParams', updatedJobParams);

    createJobMutation.mutate({
      ...formik.values,
      jobParams: updatedJobParams,
    });
    setShowOrthoPrepModal(false);
    formik.resetForm();
  };

  return (
    <Form onSubmit={formik.handleSubmit} className="row g-3 mb-4">
      <Col md={3}>
        <select
          name="jobType"
          onChange={(e) => {
            const value = e.target.value || '';

            formik.setFieldValue('jobType', value);
            formik.setFieldValue('jobParams', {});
          }}
          onBlur={formik.handleBlur}
          value={formik.values.jobType}
          className={`form-control ${
            formik.touched.jobType && formik.errors.jobType && 'is-invalid'
          }`}
        >
          {Object.keys(jobSelectionTemplate).map((value) => (
            <option key={value} value={value}>
              {jobSelectionTemplate[value].text}
            </option>
          ))}
        </select>
        {formik.touched.jobType && formik.errors.jobType && (
          <div className="invalid-feedback">{formik.errors.jobType}</div>
        )}
      </Col>

      <Col md={9}>
        <Button
          variant="primary"
          type="submit"
          disabled={isSubmitButtonDisable}
        >
          {isLoading ? 'Loading...' : 'Submit'}
        </Button>
      </Col>

      {jobSelectionTemplate[formik.values.jobType]?.template}

      <RetryCancelModal
        heading="Error ❌"
        message="Do you want to retry the DR job or cancel it?"
        onHide={handleCancelRetry} // Function to close the modal
        submitHandler={handleRetryJob} // Function to retry the job
        show={showRetryModal} // Control modal visibility
      />

      {/* OrthoPrepModal */}
      {showOrthoPrepModal && (
        <OrthoPrepModal
          heading="🔎 Verification"
          params={params}
          body="Check the details before moving forward"
          onSubmit={handleOrthoPrepSubmit}
          onHide={handleCancelOrthoPrep}
          show={showOrthoPrepModal}
          setLoading={setLoading}
          jobType={jobSelectionTemplate[formik.values.jobType]}
        />
      )}
    </Form>
  );
};

CreateJob.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onNotFoundError: PropTypes.func,
  onOrthoError: PropTypes.func,
  onOrthoLessError: PropTypes.func,
  onOrthoMoreError: PropTypes.func,
  onOrthoOldFileError: PropTypes.func,
  onOrthoVersionFoundError: PropTypes.func,
};

export default CreateJob;
