import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import { DRfilesCheck, OrthoFileCheck } from '../api';

import './css/OrthoPrepModal.css';

const patterns = [
  /\d{8}_\d_\d{8}_\d_\d{8}_\d_/, // Pattern 1
  /\d{8}_\d{8}_\d{8}/, // Pattern 2
];

const extractDateRange = (filePath, patterns) => {
  let firstValidMatch = null;

  patterns.some((pattern) => {
    const [match] = pattern.exec(filePath) || [];
    if (match && !firstValidMatch) {
      firstValidMatch = match;
      return true;
    }
    return false;
  });

  return firstValidMatch;
};

const getDateFromFileName = (fileName) => {
  // Patterns for date extraction
  const datePatterns = [
    /\d{8}/, // Add more patterns as needed
  ];

  let date = '';

  // Try each pattern until a match is found
  datePatterns.some((pattern) => {
    const matches = fileName.match(pattern) || [];
    const [match] = matches.filter(Boolean); // Filter out undefined or empty matches
    if (match) {
      date = match;
      return true; // Stop searching after finding a match
    }
    return false;
  });

  return date;
};

const OrthoPrepModal = (props) => {
  const { show, heading, body, onHide, onSubmit, setLoading, params, jobType } =
    props;
  const paramsRef = useRef(params);

  const [fileType, setFileType] = useState('');
  const [imagesList, setImagesList] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [needOrtho, setNeedOrtho] = useState('');
  const [orthoFileCheckResult, setOrthoFileCheckResult] = useState(null);
  const [fileTypeDateRange, setFileTypeDateRange] = useState('');
  const [refreshData, setRefreshData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  console.log('Job type: ', jobType);

  const fetchData = async () => {
    setIsLoading(true);
    const currentParams = paramsRef.current;

    try {
      const orthoFileCheckResult = await OrthoFileCheck(
        currentParams.country,
        currentParams.tile,
        currentParams.DR_TILES_VER
      );

      // Check if the parameters have changed while fetching data
      if (currentParams !== paramsRef.current) {
        return;
      }

      setOrthoFileCheckResult(orthoFileCheckResult);

      console.log('orthoFileCheckResult---', orthoFileCheckResult);

      if (
        orthoFileCheckResult.object_count <= 225 &&
        orthoFileCheckResult.object_count > 0
      ) {
        setNeedOrtho('false');
      }

      const dateRangeMatch = extractDateRange(
        orthoFileCheckResult.object_file_name,
        patterns
      );
      setFileTypeDateRange(dateRangeMatch);

      if (
        fileType &&
        currentParams.country &&
        currentParams.tile &&
        currentParams.DR_TILES_VER
      ) {
        const response = await DRfilesCheck(
          currentParams.country,
          currentParams.tile,
          fileType
        );

        // console.log('response----------114', response);

        if (response.files) {
          setImagesList(response.files);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false); // Set loading to false whether successful or not
    }
  };

  // ...

  useEffect(() => {
    const fetchDataAndCheckParams = async () => {
      const currentParams = paramsRef.current;

      await fetchData();

      // Use currentParams here to check whether params have changed after fetching data
      if (show || refreshData || currentParams !== paramsRef.current) {
        setRefreshData(false);
      }
    };

    if (show) {
      fetchDataAndCheckParams();
    }
  }, [show, fileType, refreshData, paramsRef.current]);

  useEffect(() => {
    setSelectedCheckboxes([]);
  }, [fileType]);

  useEffect(() => {
    // Set needOrtho to 'true' when object_count is 0
    if (orthoFileCheckResult && orthoFileCheckResult.object_count === 0) {
      setNeedOrtho('true');
    }
  }, [orthoFileCheckResult]);

  const handleFileTypeChange = (event) => {
    setFileType(event.target.value);
  };

  const handleCheckboxChange = (value) => {
    const updatedCheckboxes = [...selectedCheckboxes];

    if (updatedCheckboxes.includes(value)) {
      updatedCheckboxes.splice(updatedCheckboxes.indexOf(value), 1);
    } else {
      updatedCheckboxes.push(value);
    }

    // Log extracted dates before sorting
    console.log('Before Sorting:', updatedCheckboxes.map(getDateFromFileName));

    updatedCheckboxes.sort((a, b) => {
      const dateA = getDateFromFileName(a);
      const dateB = getDateFromFileName(b);
      return parseInt(dateA, 10) - parseInt(dateB, 10);
    });

    // Log extracted dates after sorting
    console.log('After Sorting:', updatedCheckboxes.map(getDateFromFileName));

    setSelectedCheckboxes(updatedCheckboxes.slice(0, 3));
  };

  const handleNeedOrthoChange = (event) => {
    console.log('ortho event #####', event.target.value);
    const newValue = event.target.value === 'true' ? 'true' : 'false';
    setNeedOrtho(newValue);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    const orthoPrepValues = {
      images_list: selectedCheckboxes.length !== 0 ? selectedCheckboxes : '',
      file_type: fileType,
      need_ortho: needOrtho,
    };

    try {
      // Call the parent onSubmit function
      await onSubmit(orthoPrepValues);
    } finally {
      // Reset loading state after submission (whether successful or not)
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setLoading(false);
    setRefreshData(true);
    setFileType('');
    setImagesList([]);
    setSelectedCheckboxes([]);
    setNeedOrtho('');
    setOrthoFileCheckResult(null);
    setFileTypeDateRange('');
    onHide();
  };

  useEffect(() => {
    paramsRef.current = params;
  }, [params]);

  return (
    <Modal
      key={show ? 'visible' : 'hidden'}
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      className="customOrthoModal"
    >
      <Modal.Header closeButton onClick={handleCancel}>
        <Modal.Title>{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && (
          <div className="text-center">
            <Spinner animation="border" variant="primary" />
            <p>Loading...</p>
          </div>
        )}
        {!isLoading && (
          <>
            <h3>{body}</h3>
            {orthoFileCheckResult && (
              <div>
                <h4>Information to verify</h4>
                <p>
                  File count for ortho-prep version {params.DR_TILES_VER}:{' '}
                  {orthoFileCheckResult && orthoFileCheckResult.object_count}
                </p>
                <p>
                  Dates used in ortho-prep version {params.DR_TILES_VER}:{' '}
                  {fileTypeDateRange || 'N/A'}
                </p>
              </div>
            )}

            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="file_type">
                <Form.Label>File Type</Form.Label>
                <Form.Control
                  as="select"
                  value={fileType}
                  onChange={handleFileTypeChange}
                >
                  <option value="">Select File Type</option>
                  <option value="MS_cog">MS COG</option>
                  <option value="rgbn_cog">RGBN COG</option>
                </Form.Control>
              </Form.Group>
              {fileType !== '' ? (
                <Form.Group controlId="images_list" className="images_list">
                  {orthoFileCheckResult &&
                  orthoFileCheckResult.object_count === 0 ? (
                    <Form.Label>Select three files for ortho-prep</Form.Label>
                  ) : (
                    <Form.Label>Image List</Form.Label>
                  )}
                  {orthoFileCheckResult &&
                  orthoFileCheckResult.object_count === 0 ? (
                    imagesList.map((value) => (
                      <Form.Check
                        key={value.key}
                        type="checkbox"
                        id={`checkbox-${value.key}`}
                        label={`${value.key} || SIZE: ${Math.round(
                          value.size / 1024 ** 3
                        )} GB`}
                        checked={selectedCheckboxes.includes(value.key)}
                        onChange={() => handleCheckboxChange(value.key)}
                      />
                    ))
                  ) : orthoFileCheckResult &&
                    orthoFileCheckResult.object_count === 225 ? (
                    <Form.Control
                      as="select"
                      multiple
                      className="form-control-images-list"
                    >
                      {imagesList.map((value) => (
                        <option key={value.key}>
                          {`${value.key} || SIZE: ${Math.round(
                            value.size / 1024 ** 3
                          )} GB`}
                        </option>
                      ))}
                    </Form.Control>
                  ) : (
                    <Form.Control
                      as="select"
                      className="form-control-images-list"
                      multiple
                    >
                      {imagesList.map((value) => (
                        <option key={value.key}>{`${
                          value.key
                        } || SIZE: ${Math.round(
                          value.size / 1024 ** 3
                        )} GB`}</option>
                      ))}
                    </Form.Control>
                  )}
                </Form.Group>
              ) : (
                ''
              )}
              <Form.Group controlId="need_ortho">
                <Form.Label>Need Ortho</Form.Label>
                <Form.Control
                  as="select"
                  value={needOrtho}
                  onChange={handleNeedOrthoChange}
                  disabled={
                    orthoFileCheckResult &&
                    orthoFileCheckResult.object_count <= 225 &&
                    orthoFileCheckResult.object_count > 0
                  }
                >
                  {orthoFileCheckResult &&
                  orthoFileCheckResult.object_count === 0 ? (
                    <option value="true">True</option>
                  ) : (
                    <>
                      <option value="false">False</option>
                      <option value="true">True</option>
                    </>
                  )}
                </Form.Control>
              </Form.Group>

              {jobType.text === 'ORTHO_PREP_TASK' &&
              orthoFileCheckResult &&
              orthoFileCheckResult.object_count !== 0 ? null : (
                <Button
                  variant="primary"
                  type="submit"
                  disabled={
                    !(selectedCheckboxes.length === 3 || needOrtho === 'false')
                  }
                >
                  Submit
                </Button>
              )}
              <Button variant="secondary" onClick={handleCancel}>
                Cancel
              </Button>
            </Form>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

OrthoPrepModal.propTypes = {
  show: PropTypes.bool.isRequired,
  heading: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  params: PropTypes.object.isRequired,
  jobType: PropTypes.string.isRequired,
};

export default OrthoPrepModal;
