/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';
import {
  DRV2,
  DRV3,
  DRV2_TASK_CHAINING,
  INFERENCE,
} from '../constants/jobType';

const jobSchema = Yup.object().shape({
  jobType: Yup.string().trim().required('Required'),
  jobParams: Yup.object()
    .when('jobType', {
      is: (val) => val === DRV2 || val === DRV2_TASK_CHAINING || val === DRV3,
      then: Yup.object().shape({
        mgrs: Yup.string()
          .matches(/^\d{2}[A-Z]{3}$/gi, 'Please use the correct mgrs tile name')
          .required('Required'),
        date: Yup.string()
          .matches(
            /^(\d{4})(-)(\d{1,2})(-)(\d{1,2})$/,
            'Please provide correct date with index'
          )
          .length(10)
          .required('Required'),
        tags: Yup.string()
          .trim('The client name name cannot include any space')
          .required('Required'),
        country: Yup.string().strict(true),
      }),
    })
    .when('jobType', {
      is: (val) => val === INFERENCE,
      then: Yup.object().shape({
        country: Yup.string().required('Required'),
        tile: Yup.string()
          .matches(/^\d{2}[A-Z]{3}$/gi, 'Please use the correct mgrs tile name')
          .required('Required'),
        DR_TILES_VER: Yup.string().required('Required'),
        version: Yup.number().required('Required'),
        model: Yup.string().test(
          'no-spaces',
          'Model cannot include any space',
          (value) => !value || !/\s/.test(value)
        ),
        // server: Yup.string().trim('server').required('Required'),
      }),
    }),
});

export default jobSchema;
