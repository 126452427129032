/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { Col } from 'react-bootstrap';

import PropTypes from 'prop-types';
import COUNTRY_ABBRS from '../../../constants/countries';
import './Template.css';

const Ortho = ({ formik }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);

    // Filter the results based on the input value
    const filteredResults = Object.keys(COUNTRY_ABBRS).filter((country) =>
      country.toLowerCase().includes(value.toLowerCase())
    );

    setSearchResults(filteredResults);
  };

  const handleResultClick = (selectedResult) => {
    formik.setFieldValue('jobParams.country', COUNTRY_ABBRS[selectedResult]);
    setSearchTerm(selectedResult);
    setSearchResults([]);
  };
  return (
    <>
      <Col md>
        <input
          type="text"
          name="jobParams.country"
          placeholder="Country Code"
          onChange={handleInputChange}
          onBlur={formik.handleBlur}
          value={searchTerm}
          className={`form-control ${
            formik.touched.jobParams?.country &&
            formik.errors.jobParams?.country &&
            'is-invalid'
          }`}
        />
        {searchTerm && searchResults.length > 0 && (
          <div className="search-results">
            <ul>
              {searchResults.map((result) => (
                <li key={result} className="search-result-item">
                  <button
                    onClick={() => handleResultClick(result)}
                    type="button"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleResultClick(result);
                      }
                    }}
                  >
                    {result}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}

        {formik.touched.jobParams?.country &&
          formik.errors.jobParams?.country && (
            <div className="invalid-feedback">
              {formik.errors.jobParams.country}
            </div>
          )}
      </Col>

      <Col md>
        <input
          type="text"
          name="jobParams.tile"
          placeholder="MGRS"
          onChange={(e) => {
            const value = e.target.value || '';
            formik.setFieldValue('jobParams.tile', value.toUpperCase());
          }}
          onBlur={formik.handleBlur}
          value={formik.values.jobParams?.tile ?? ''}
          className={`form-control ${
            formik.touched.jobParams?.tile &&
            formik.errors.jobParams?.tile &&
            'is-invalid'
          }`}
        />
        {formik.touched.jobParams?.tile && formik.errors.jobParams?.tile && (
          <div className="invalid-feedback">{formik.errors.jobParams.tile}</div>
        )}
      </Col>

      <Col md>
        <input
          type="number"
          name="jobParams.DR_TILES_VER"
          placeholder="DR Tile Version"
          onChange={(e) => {
            const value = e.target.value || '';

            formik.setFieldValue('jobParams.DR_TILES_VER', value);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.jobParams?.DR_TILES_VER ?? ''}
          className={`form-control ${
            formik.touched.jobParams?.DR_TILES_VER &&
            formik.errors.jobParams?.DR_TILES_VER &&
            'is-invalid'
          }`}
        />
        {formik.touched.jobParams?.DR_TILES_VER &&
          formik.errors.jobParams?.DR_TILES_VER && (
            <div className="invalid-feedback">
              {formik.errors.jobParams.DR_TILES_VER}
            </div>
          )}
      </Col>
    </>
  );
};

Ortho.propTypes = {
  formik: PropTypes.object,
};

export default Ortho;
