import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

const ModalWindow = (props) => {
  const { heading, message, submitHandler, ...setting } = props;
  return (
    <Modal
      {...setting}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={setting.onHide}>Close</Button>
        {submitHandler && (
          <Button variant="primary" onClick={submitHandler}>
            Save changes
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

ModalWindow.propTypes = {
  heading: PropTypes.string,
  message: PropTypes.string,
  onHide: PropTypes.func,
  submitHandler: PropTypes.func,
};

export default ModalWindow;
