/* eslint-disable react/prop-types */
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import TimeChange from '../../components/TimeChange';
import Status from '../../components/Status';

const JobStepTable = ({ data, showLogsHandler }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Job Step',
        accessor: 'job_type',
        Cell: ({ value }) => value.replaceAll('_', ' '),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => <Status state={value} />,
      },
      {
        Header: 'Pickedup At',
        accessor: 'pickedup_time',
        Cell: ({ value }) => <TimeChange date={value} />,
      },
      {
        Header: 'Ended At',
        accessor: 'end_time',
        Cell: ({ value }) => <TimeChange date={value} />,
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <Table striped bordered responsive size="sm" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className="text-center">
                {column.render('Header')}
              </th>
            ))}
            <th className="text-center">Options</th>
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);

          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()} className="align-middle">
                  {cell.render('Cell')}
                </td>
              ))}
              <td className="text-center">
                <Button
                  variant="info"
                  size="sm"
                  disabled={!row?.original?.ecs_props?.log}
                  onClick={() => showLogsHandler(row?.original)}
                >
                  Show Log
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

JobStepTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object.isRequired),
  showLogsHandler: PropTypes.func,
};

export default JobStepTable;
