import moment from 'moment';

const TimeChange = (prop) => {
  const { date } = prop;
  return (
    <div>
      {date && moment.utc(date).local().format('ddd YYYY-MM-DD, h:mm a')}
    </div>
  );
};

export default TimeChange;
