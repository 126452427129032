const COUNTRY_ABBRS = {
  'Afghanistan (AF)': 'AF',
  'Albania (AL)': 'AL',
  'Algeria (DZ)': 'DZ',
  'American Samoa (AS)': 'AS',
  'Andorra (AD)': 'AD',
  'Angola (AO)': 'AO',
  'Anguilla (AI)': 'AI',
  'Antarctica (AQ)': 'AQ',
  'Antigua and Barbuda (AG)': 'AG',
  'Argentina (AR)': 'AR',
  'Armenia (AM)': 'AM',
  'Aruba (AW)': 'AW',
  'Australia (AU)': 'AU',
  'Austria (AT)': 'AT',
  'Azerbaijan (AZ)': 'AZ',
  'Bahamas (the) (BS)': 'BS',
  'Bahrain (BH)': 'BH',
  'Bangladesh (BD)': 'BD',
  'Barbados (BB)': 'BB',
  'Belarus (BY)': 'BY',
  'Belgium (BE)': 'BE',
  'Belize (BZ)': 'BZ',
  'Benin (BJ)': 'BJ',
  'Bermuda (BM)': 'BM',
  'Bhutan (BT)': 'BT',
  'Bolivia (Plurinational State of) (BO)': 'BO',
  'Bonaire, Sint Eustatius and Saba (BQ)': 'BQ',
  'Bosnia and Herzegovina (BA)': 'BA',
  'Botswana (BW)': 'BW',
  'Bouvet Island (BV)': 'BV',
  'Brazil (BR)': 'BR',
  'British Indian Ocean Territory (the) (IO)': 'IO',
  'Brunei Darussalam (BN)': 'BN',
  'Bulgaria (BG)': 'BG',
  'Burkina Faso (BF)': 'BF',
  'Burundi (BI)': 'BI',
  'Cabo Verde (CV)': 'CV',
  'Cambodia (KH)': 'KH',
  'Cameroon (CM)': 'CM',
  'Canada (CA)': 'CA',
  'Cayman Islands (the) (KY)': 'KY',
  'Central African Republic (the) (CF)': 'CF',
  'Chad (TD)': 'TD',
  'Chile (CL)': 'CL',
  'China (CN)': 'CN',
  'Christmas Island (CX)': 'CX',
  'Cocos (Keeling) Islands (the) (CC)': 'CC',
  'Colombia (CO)': 'CO',
  'Comoros (the) (KM)': 'KM',
  'Congo (the Democratic Republic of the) (CD)': 'CD',
  'Congo (the) (CG)': 'CG',
  'Cook Islands (the) (CK)': 'CK',
  'Costa Rica (CR)': 'CR',
  'Croatia (HR)': 'HR',
  'Cuba (CU)': 'CU',
  'Curaçao (CW)': 'CW',
  'Cyprus (CY)': 'CY',
  'Czechia (CZ)': 'CZ',
  "Côte d'Ivoire (CI)": 'CI',
  'Denmark (DK)': 'DK',
  'Djibouti (DJ)': 'DJ',
  'Dominica (DM)': 'DM',
  'Dominican Republic (the) (DO)': 'DO',
  'Ecuador (EC)': 'EC',
  'Egypt (EG)': 'EG',
  'El Salvador (SV)': 'SV',
  'Equatorial Guinea (GQ)': 'GQ',
  'Eritrea (ER)': 'ER',
  'Estonia (EE)': 'EE',
  'Eswatini (SZ)': 'SZ',
  'Ethiopia (ET)': 'ET',
  'Falkland Islands (the) [Malvinas] (FK)': 'FK',
  'Faroe Islands (the) (FO)': 'FO',
  'Fiji (FJ)': 'FJ',
  'Finland (FI)': 'FI',
  'France (FR)': 'FR',
  'French Guiana (GF)': 'GF',
  'French Polynesia (PF)': 'PF',
  'French Southern Territories (the) (TF)': 'TF',
  'Gabon (GA)': 'GA',
  'Gambia (the) (GM)': 'GM',
  'Georgia (GE)': 'GE',
  'Germany (DE)': 'DE',
  'Ghana (GH)': 'GH',
  'Gibraltar (GI)': 'GI',
  'Greece (GR)': 'GR',
  'Greenland (GL)': 'GL',
  'Grenada (GD)': 'GD',
  'Guadeloupe (GP)': 'GP',
  'Guam (GU)': 'GU',
  'Guatemala (GT)': 'GT',
  'Guernsey (GG)': 'GG',
  'Guinea (GN)': 'GN',
  'Guinea-Bissau (GW)': 'GW',
  'Guyana (GY)': 'GY',
  'Haiti (HT)': 'HT',
  'Heard Island and McDonald Islands (HM)': 'HM',
  'Holy See (the) (VA)': 'VA',
  'Honduras (HN)': 'HN',
  'Hong Kong (HK)': 'HK',
  'Hungary (HU)': 'HU',
  'Iceland (IS)': 'IS',
  'India (IN)': 'IN',
  'Indonesia (ID)': 'ID',
  'Iran (Islamic Republic of) (IR)': 'IR',
  'Iraq (IQ)': 'IQ',
  'Ireland (IE)': 'IE',
  'Isle of Man (IM)': 'IM',
  'Israel (IL)': 'IL',
  'Italy (IT)': 'IT',
  'Jamaica (JM)': 'JM',
  'Japan (JP)': 'JP',
  'Jersey (JE)': 'JE',
  'Jordan (JO)': 'JO',
  'Kazakhstan (KZ)': 'KZ',
  'Kenya (KE)': 'KE',
  'Kiribati (KI)': 'KI',
  "Korea (the Democratic People's Republic of) (KP)": 'KP',
  'Korea (the Republic of) (KR)': 'KR',
  'Kuwait (KW)': 'KW',
  'Kyrgyzstan (KG)': 'KG',
  "Lao People's Democratic Republic (the) (LA)": 'LA',
  'Latvia (LV)': 'LV',
  'Lebanon (LB)': 'LB',
  'Lesotho (LS)': 'LS',
  'Liberia (LR)': 'LR',
  'Libya (LY)': 'LY',
  'Liechtenstein (LI)': 'LI',
  'Lithuania (LT)': 'LT',
  'Luxembourg (LU)': 'LU',
  'Macao (MO)': 'MO',
  'Madagascar (MG)': 'MG',
  'Malawi (MW)': 'MW',
  'Malaysia (MY)': 'MY',
  'Maldives (MV)': 'MV',
  'Mali (ML)': 'ML',
  'Malta (MT)': 'MT',
  'Marshall Islands (the) (MH)': 'MH',
  'Martinique (MQ)': 'MQ',
  'Mauritania (MR)': 'MR',
  'Mauritius (MU)': 'MU',
  'Mayotte (YT)': 'YT',
  'Mexico (MX)': 'MX',
  'Micronesia (Federated States of) (FM)': 'FM',
  'Moldova (the Republic of) (MD)': 'MD',
  'Monaco (MC)': 'MC',
  'Mongolia (MN)': 'MN',
  'Montenegro (ME)': 'ME',
  'Montserrat (MS)': 'MS',
  'Morocco (MA)': 'MA',
  'Mozambique (MZ)': 'MZ',
  'Myanmar (MM)': 'MM',
  'Namibia (NA)': 'NA',
  'Nauru (NR)': 'NR',
  'Nepal (NP)': 'NP',
  'Netherlands (the) (NL)': 'NL',
  'New Caledonia (NC)': 'NC',
  'New Zealand (NZ)': 'NZ',
  'Nicaragua (NI)': 'NI',
  'Niger (the) (NE)': 'NE',
  'Nigeria (NG)': 'NG',
  'Niue (NU)': 'NU',
  'Norfolk Island (NF)': 'NF',
  'Northern Mariana Islands (the) (MP)': 'MP',
  'Norway (NO)': 'NO',
  'Oman (OM)': 'OM',
  'Pakistan (PK)': 'PK',
  'Palau (PW)': 'PW',
  'Palestine, State of (PS)': 'PS',
  'Panama (PA)': 'PA',
  'Papua New Guinea (PG)': 'PG',
  'Paraguay (PY)': 'PY',
  'Peru (PE)': 'PE',
  'Philippines (the) (PH)': 'PH',
  'Pitcairn (PN)': 'PN',
  'Poland (PL)': 'PL',
  'Portugal (PT)': 'PT',
  'Puerto Rico (PR)': 'PR',
  'Qatar (QA)': 'QA',
  'Republic of North Macedonia (MK)': 'MK',
  'Romania (RO)': 'RO',
  'Russian Federation (the) (RU)': 'RU',
  'Rwanda (RW)': 'RW',
  'Réunion (RE)': 'RE',
  'Saint Barthélemy (BL)': 'BL',
  'Saint Helena, Ascension and Tristan da Cunha (SH)': 'SH',
  'Saint Kitts and Nevis (KN)': 'KN',
  'Saint Lucia (LC)': 'LC',
  'Saint Martin (French part) (MF)': 'MF',
  'Saint Pierre and Miquelon (PM)': 'PM',
  'Saint Vincent and the Grenadines (VC)': 'VC',
  'Samoa (WS)': 'WS',
  'San Marino (SM)': 'SM',
  'Sao Tome and Principe (ST)': 'ST',
  'Saudi Arabia (SA)': 'SA',
  'Senegal (SN)': 'SN',
  'Serbia (RS)': 'RS',
  'Seychelles (SC)': 'SC',
  'Sierra Leone (SL)': 'SL',
  'Singapore (SG)': 'SG',
  'Sint Maarten (Dutch part) (SX)': 'SX',
  'Slovakia (SK)': 'SK',
  'Slovenia (SI)': 'SI',
  'Solomon Islands (SB)': 'SB',
  'Somalia (SO)': 'SO',
  'South Africa (ZA)': 'ZA',
  'South Georgia and the South Sandwich Islands (GS)': 'GS',
  'South Sudan (SS)': 'SS',
  'Spain (ES)': 'ES',
  'Sri Lanka (LK)': 'LK',
  'Sudan (the) (SD)': 'SD',
  'Suriname (SR)': 'SR',
  'Svalbard and Jan Mayen (SJ)': 'SJ',
  'Sweden (SE)': 'SE',
  'Switzerland (CH)': 'CH',
  'Syrian Arab Republic (SY)': 'SY',
  'Taiwan (Province of China) (TW)': 'TW',
  'Tajikistan (TJ)': 'TJ',
  'Tanzania, United Republic of (TZ)': 'TZ',
  'Thailand (TH)': 'TH',
  'Timor-Leste (TL)': 'TL',
  'Togo (TG)': 'TG',
  'Tokelau (TK)': 'TK',
  'Tonga (TO)': 'TO',
  'Trinidad and Tobago (TT)': 'TT',
  'Tunisia (TN)': 'TN',
  'Turkey (TR)': 'TR',
  'Turkmenistan (TM)': 'TM',
  'Turks and Caicos Islands (the) (TC)': 'TC',
  'Tuvalu (TV)': 'TV',
  'Uganda (UG)': 'UG',
  'Ukraine (UA)': 'UA',
  'United Arab Emirates (the) (AE)': 'AE',
  'United Kingdom of Great Britain and Northern Ireland (the) (GB)': 'GB',
  'United States Minor Outlying Islands (the) (UM)': 'UM',
  'United States of America (the) (US)': 'US',
  'Uruguay (UY)': 'UY',
  'Uzbekistan (UZ)': 'UZ',
  'Vanuatu (VU)': 'VU',
  'Venezuela (Bolivarian Republic of) (VE)': 'VE',
  'Viet Nam (VN)': 'VN',
  'Virgin Islands (British) (VG)': 'VG',
  'Virgin Islands (U.S.) (VI)': 'VI',
  'Wallis and Futuna (WF)': 'WF',
  'Western Sahara (EH)': 'EH',
  'Yemen (YE)': 'YE',
  'Zambia (ZM)': 'ZM',
  'Zimbabwe (ZW)': 'ZW',
  'Åland Islands (AX)': 'AX',
};

export default COUNTRY_ABBRS;
