import React, { useEffect, useState } from 'react';
import { useTable, useExpanded } from 'react-table';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import Button from 'react-bootstrap/Button';
import { Funnel, FunnelFill, ArrowClockwise } from 'react-bootstrap-icons';
import { Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './jobtable.css';
import FilterSearchForm from '../Filters/FilterSeachForm';

import { FilterProvider, useFilter } from '../Filters/FilterContext'; // Import FilterProvider and useFilter from your context file

const PER_PAGE_ITEM_SHOW = 10;

const JobTable = ({
  columns,
  data,
  dataUpdatedAt,
  pageNumber,
  setPageNumber,
  count,
  showLogsHandler,
  setJobKillFlag,
}) => {
  const {
    filterValue,
    setFilterValue,
    filterStatus,
    setFilterStatus,
    searchValue,
    setSearchValue,
    searchId,
    setSearchId,
  } = useFilter();

  const [filteredData, setFilteredData] = useState(data);
  const [filteredCount, setFilteredCount] = useState(data.length);
  const [showFilterSearch, setShowFilterSearch] = useState(true);
  // Options for the filter dropdown
  const filterOptions = [
    { value: '', label: 'All' },
    { value: 'DR', label: 'DR' },
    { value: 'DRV2', label: 'DRV2' },
  ];

  // Options for the status filter dropdown
  const statusFilterOptions = [
    { value: '', label: 'All', statusBg: 'light' },
    { value: 'running', label: 'Running', statusBg: 'info' },
    { value: 'failed', label: 'Failed', statusBg: 'danger' },
    { value: 'submitted', label: 'Submitted', statusBg: 'warning' },
    { value: 'done', label: 'Done', statusBg: 'success' },
    { value: 'killed', label: 'Killed', statusBg: 'secondary' },
  ];

  const handleFilterChange = (event) => {
    setFilterValue(event.target.value); // Update filter value
  };

  const handleStatusFilterChange = (event) => {
    setFilterStatus(event.target.value); // Update status filter value
  };

  const handleReset = () => {
    setFilterValue(''); // Reset filter value
    setFilterStatus(''); // Reset status filter value
    setSearchValue(''); // Reset search value
    setSearchId(''); // Reset searchId
    setShowFilterSearch(true);
  };

  const filterData = () => {
    // Apply the combined filter to the data
    let newData = data;
    // console.log(newData);

    if (filterValue) {
      newData = newData.filter((item) => item.job_type === filterValue);
    }

    if (filterStatus) {
      newData = newData.filter((item) => item.status === filterStatus);
    }

    // Add a search filter for the title
    if (searchValue) {
      newData = newData.filter((item) => {
        const hasTile =
          item.job_params?.mgrs_t &&
          item.job_params?.mgrs_t
            .toLowerCase()
            .includes(searchValue.toLowerCase());
        const hasMGRS =
          item.job_params?.mgrs &&
          item.job_params?.mgrs
            .toLowerCase()
            .includes(searchValue.toLowerCase());

        return hasTile || hasMGRS;
      });
    }

    if (searchId.toString()) {
      newData = newData.filter((item) => item.id.toString().includes(searchId));
    }

    setFilteredData(newData);
  };

  useEffect(() => {
    // Initial filtering
    filterData();
  }, [filterValue, filterStatus, searchValue, searchId, data]);

  useEffect(() => {
    // Update filtered count based on the filtered data
    setFilteredCount(filteredData.length);
  }, [filteredData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable(
    {
      columns,
      data: filteredData,
    },
    useExpanded
  );

  const paginationItem = [];
  const totalPages = Math.ceil(count / PER_PAGE_ITEM_SHOW);

  const pageRange = 2; // Number of pages to show before and after the current page

  let startPage = Math.max(1, pageNumber - pageRange);
  let endPage = Math.min(totalPages, pageNumber + pageRange);

  // Ensure the first and last pages are always included
  if (startPage <= pageRange) {
    endPage = Math.min(endPage + pageRange - startPage + 1, totalPages);
  }

  if (totalPages - endPage < pageRange) {
    startPage = Math.max(startPage - (pageRange - (totalPages - endPage)), 1);
  }

  // Add the first page conditionally
  if (pageNumber > pageRange + 1) {
    paginationItem.push(
      <Pagination.Item
        key={1}
        active={pageNumber === 1}
        onClick={() => setPageNumber(1)}
      >
        {1}
      </Pagination.Item>
    );

    if (pageNumber > pageRange + 2) {
      paginationItem.push(<Pagination.Ellipsis key="ellipsis-1" />);
    }
  }

  // eslint-disable-next-line no-plusplus
  for (let number = startPage; number <= endPage; number++) {
    paginationItem.push(
      <Pagination.Item
        key={number}
        active={number === pageNumber}
        onClick={() => setPageNumber(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  // Add the last page conditionally
  if (pageNumber < totalPages - pageRange) {
    if (pageNumber < totalPages - pageRange - 1) {
      paginationItem.push(<Pagination.Ellipsis key="ellipsis-2" />);
    }

    paginationItem.push(
      <Pagination.Item
        key={totalPages}
        active={pageNumber === totalPages}
        onClick={() => setPageNumber(totalPages)}
      >
        {totalPages}
      </Pagination.Item>
    );
  }

  return (
    <div className="jobs">
      <Row className="mb-4">
        <Col xs={6} md={6} className="text-center">
          {' '}
          {/* Add this column */}
          {/* This column will act as a spacer */}
        </Col>
        <Col xs={1} md={1} style={{ marginTop: '8px', marginLeft: 'auto' }}>
          <OverlayTrigger
            placement="left"
            overlay={
              <Tooltip id="tooltip-info">
                <div className="alert-box">
                  <h6>Alert ⚠️</h6>
                  <p>
                    Please note that this feature provides filtering and
                    searching for the <strong>current page only</strong> and
                    does not go through the entire data at once.
                  </p>
                </div>
              </Tooltip>
            }
          >
            <Button
              variant="primary"
              size="md"
              onClick={() => setShowFilterSearch(!showFilterSearch)}
              className={!showFilterSearch ? 'active-button' : 'in-active'}
            >
              {!showFilterSearch ? <Funnel /> : <FunnelFill />}
            </Button>
          </OverlayTrigger>
        </Col>
        <Col xs={1} md={1} style={{ marginTop: '8px' }}>
          <Button variant="primary" size="md" onClick={handleReset}>
            <ArrowClockwise />
          </Button>
        </Col>
      </Row>
      {!showFilterSearch && (
        <FilterSearchForm
          filterValue={filterValue}
          handleFilterChange={handleFilterChange}
          filterOptions={filterOptions}
          filterStatus={filterStatus}
          handleStatusFilterChange={handleStatusFilterChange}
          statusFilterOptions={statusFilterOptions}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          searchId={searchId}
          setSearchId={setSearchId}
        />
      )}
      <Row className="tableContainer mb-4">
        <Table
          striped
          bordered
          hover
          responsive
          size="sm"
          className="jobTable my-4"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className="text-center align-middle"
                  >
                    {column.render('Header')}
                  </th>
                ))}
                <th className="text-center">Options</th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const canKill =
                ['running', 'submitted'].includes(row.original.status) &&
                !row.original.kill;
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()} className="align-middle">
                      {cell.render('Cell') || 'No Data'}
                    </td>
                  ))}

                  <td className="text-center">
                    <Button
                      variant="info"
                      size="sm"
                      disabled={!row?.original?.ecs_props?.log}
                      onClick={() => showLogsHandler(row?.original)}
                    >
                      Show Log
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Row>
      <Row>
        <Pagination>
          <Pagination.Prev
            style={{ marginRight: 15 }}
            disabled={pageNumber === 1}
            onClick={() => setPageNumber(pageNumber - 1)}
          />
          {paginationItem}
          <Pagination.Next
            style={{ marginLeft: 15 }}
            disabled={pageNumber === totalPages}
            onClick={() => setPageNumber(pageNumber + 1)}
          />
        </Pagination>
      </Row>
    </div>
  );
};

JobTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object.isRequired),
  data: PropTypes.arrayOf(PropTypes.object.isRequired),
  dataUpdatedAt: PropTypes.number,
  setPageNumber: PropTypes.func,
  pageNumber: PropTypes.number,
  count: PropTypes.number,
  showLogsHandler: PropTypes.func,
  setJobKillFlag: PropTypes.func,
};

export default JobTable;
