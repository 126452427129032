import React, { createContext, useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [filterValue, setFilterValue] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchId, setSearchId] = useState('');

  const value = useMemo(
    () => ({
      filterValue,
      setFilterValue,
      filterStatus,
      setFilterStatus,
      searchValue,
      setSearchValue,
      searchId,
      setSearchId,
    }),
    [
      filterValue,
      setFilterValue,
      filterStatus,
      setFilterStatus,
      searchValue,
      setSearchValue,
      searchId,
      setSearchId,
    ]
  );

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
};

export const useFilter = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useFilter must be used within a FilterProvider');
  }
  return context;
};

FilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
