import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

const FilterSearchForm = ({
  filterValue,
  handleFilterChange,
  filterOptions,
  filterStatus,
  handleStatusFilterChange,
  statusFilterOptions,
  searchValue,
  setSearchValue,
  searchId,
  setSearchId,
}) => {
  console.log('Form');
  return (
    <Row className="mb-4">
      <Col xs md={3} style={{ marginLeft: '0px' }}>
        <Form.Group controlId="filterDropdown">
          <Form.Label>Filter by Job Type:</Form.Label>
          <Form.Control
            as="select"
            onChange={handleFilterChange}
            value={filterValue}
          >
            {filterOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col xs md={3} style={{ marginLeft: '0px' }}>
        <Form.Group controlId="statusFilterDropdown">
          <Form.Label>Filter by Status:</Form.Label>
          <Form.Control
            as="select"
            onChange={handleStatusFilterChange}
            value={filterStatus}
          >
            {statusFilterOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col xs md={3} style={{ marginLeft: '0px' }}>
        <Form.Group controlId="tileSearch">
          <Form.Label>Search by Tile:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter tile to search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Form.Group>
      </Col>
      <Col xs md={3} style={{ marginLeft: '0px' }}>
        <Form.Group controlId="idSearch">
          <Form.Label>Search by ID:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter ID to search"
            value={searchId}
            onChange={(e) => setSearchId(e.target.value)}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

FilterSearchForm.propTypes = {
  filterValue: PropTypes.string,
  handleFilterChange: PropTypes.func,
  filterOptions: PropTypes.arrayOf(PropTypes.object.isRequired),
  filterStatus: PropTypes.string,
  handleStatusFilterChange: PropTypes.func,
  statusFilterOptions: PropTypes.arrayOf(PropTypes.object.isRequired),
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  searchId: PropTypes.string,
  setSearchId: PropTypes.func,
};

export default FilterSearchForm;
