/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { Col } from 'react-bootstrap';

import PropTypes from 'prop-types';
import COUNTRY_ABBRS from '../../../constants/countries';
import './Template.css';

const Inference = ({ formik }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);

    // Filter the results based on the input value
    const filteredResults = Object.keys(COUNTRY_ABBRS).filter((country) =>
      country.toLowerCase().includes(value.toLowerCase())
    );

    setSearchResults(filteredResults);
  };

  const handleResultClick = (selectedResult) => {
    formik.setFieldValue('jobParams.country', COUNTRY_ABBRS[selectedResult]);
    setSearchTerm(selectedResult);
    setSearchResults([]);
  };
  return (
    <>
      <Col md>
        <input
          type="text"
          name="jobParams.country"
          placeholder="Country Code"
          onChange={handleInputChange}
          onBlur={formik.handleBlur}
          value={searchTerm}
          className={`form-control ${
            formik.touched.jobParams?.country &&
            formik.errors.jobParams?.country &&
            'is-invalid'
          }`}
        />
        {searchTerm && searchResults.length > 0 && (
          <div className="search-results">
            <ul>
              {searchResults.map((result) => (
                <li key={result} className="search-result-item">
                  <button
                    onClick={() => handleResultClick(result)}
                    type="button"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleResultClick(result);
                      }
                    }}
                  >
                    {result}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}

        {formik.touched.jobParams?.country &&
          formik.errors.jobParams?.country && (
            <div className="invalid-feedback">
              {formik.errors.jobParams.country}
            </div>
          )}
      </Col>

      <Col md>
        <input
          type="text"
          name="jobParams.tile"
          placeholder="MGRS"
          onChange={(e) => {
            const value = e.target.value || '';
            formik.setFieldValue('jobParams.tile', value.toUpperCase());
          }}
          onBlur={formik.handleBlur}
          value={formik.values.jobParams?.tile ?? ''}
          className={`form-control ${
            formik.touched.jobParams?.tile &&
            formik.errors.jobParams?.tile &&
            'is-invalid'
          }`}
        />
        {formik.touched.jobParams?.tile && formik.errors.jobParams?.tile && (
          <div className="invalid-feedback">{formik.errors.jobParams.tile}</div>
        )}
      </Col>

      <Col md>
        <input
          type="number"
          name="jobParams.DR_TILES_VER"
          placeholder="DR Tile Version"
          onChange={(e) => {
            const value = e.target.value || '';

            formik.setFieldValue('jobParams.DR_TILES_VER', value);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.jobParams?.DR_TILES_VER ?? ''}
          className={`form-control ${
            formik.touched.jobParams?.DR_TILES_VER &&
            formik.errors.jobParams?.DR_TILES_VER &&
            'is-invalid'
          }`}
        />
        {formik.touched.jobParams?.DR_TILES_VER &&
          formik.errors.jobParams?.DR_TILES_VER && (
            <div className="invalid-feedback">
              {formik.errors.jobParams.DR_TILES_VER}
            </div>
          )}
      </Col>

      <Col md>
        <input
          type="text"
          name="jobParams.model"
          placeholder="Model"
          onChange={(e) => {
            const value = e.target.value || '';
            formik.setFieldValue('jobParams.model', value);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.jobParams?.model ?? ''}
          className={`form-control ${
            formik.touched.jobParams?.model &&
            formik.errors.jobParams?.model &&
            'is-invalid'
          }`}
        />
        {formik.touched.jobParams?.model && formik.errors.jobParams?.model && (
          <div className="invalid-feedback">
            {formik.errors.jobParams.model}
          </div>
        )}
      </Col>

      <Col md>
        <input
          type="number"
          name="jobParams.version"
          placeholder="Inference Version"
          onChange={(e) => {
            const value = e.target.value || '';
            formik.setFieldValue('jobParams.version', value);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.jobParams?.version ?? ''}
          className={`form-control ${
            formik.touched.jobParams?.version &&
            formik.errors.jobParams?.version &&
            'is-invalid'
          }`}
        />
        {formik.touched.jobParams?.version &&
          formik.errors.jobParams?.version && (
            <div className="invalid-feedback">
              {formik.errors.jobParams.version}
            </div>
          )}
      </Col>
      {/* <Col md>
      <input
        type="text"
        name="jobParams.server"
        placeholder="Server"
        onChange={(e) => {
          const value = e.target.value || '';
          formik.setFieldValue('jobParams.server', value);
        }}
        onBlur={formik.handleBlur}
        value={formik.values.jobParams?.server ?? ''}
        className={`form-control ${
          formik.touched.jobParams?.server &&
          formik.errors.jobParams?.server &&
          'is-invalid'
        }`}
      />
      {formik.touched.jobParams?.server && formik.errors.jobParams?.server && (
        <div className="invalid-feedback">{formik.errors.jobParams.server}</div>
      )}
    </Col> */}
      {/* <Col md>
      <select
        name="jobParams.step"
        onChange={(e) => {
          const value = e.target.value || '';
          formik.setFieldValue('jobParams.step', value);
        }}
        onBlur={formik.handleBlur}
        value={formik.values.jobParams?.step ?? ''}
        className={`form-control ${
          formik.touched.jobParams?.step &&
          formik.errors.jobParams?.step &&
          'is-invalid'
        }`}
      >
        <option value="" disabled>
          Select Step
        </option>
        <option value="1">GPU</option>
        <option value="2">CPU</option>
      </select>
      {formik.touched.jobParams?.step && formik.errors.jobParams?.step && (
        <div className="invalid-feedback">{formik.errors.jobParams.step}</div>
      )}
    </Col> */}
      <Col md>
        <select
          name="jobParams.btype"
          onChange={(e) => {
            const value = e.target.value || '';
            formik.setFieldValue('jobParams.btype', value);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.jobParams?.btype ?? 'best_boundary'} // Set the default value to "best_boundary"
          className={`form-control ${
            formik.touched.jobParams?.btype &&
            formik.errors.jobParams?.btype &&
            'is-invalid'
          }`}
        >
          <option value="" disabled>
            Select btype
          </option>
          <option value="best_boundary">Best Boundary</option>
          <option value="best_area">Best Area</option>
        </select>
      </Col>
      <Col md>
        <select
          name="jobParams.s3_path_xn"
          onChange={(e) => {
            const value = e.target.value || '';
            formik.setFieldValue('jobParams.s3_path_xn', value);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.jobParams?.s3_path_xn ?? ''}
          className={`form-control ${
            formik.touched.jobParams?.s3_path_xn &&
            formik.errors.jobParams?.s3_path_xn &&
            'is-invalid'
          }`}
        >
          <option value="" disabled>
            Select x path
          </option>
          {[...Array(13).keys()].map((number) => (
            <option key={number} value={number}>
              {number}
            </option>
          ))}
        </select>
        {formik.touched.jobParams?.s3_path_xn &&
          formik.errors.jobParams?.s3_path_xn && (
            <div className="invalid-feedback">
              {formik.errors.jobParams.s3_path_xn}
            </div>
          )}
      </Col>
      <Col md>
        <select
          name="jobParams.s3_path_yn"
          onChange={(e) => {
            const value = e.target.value || '';
            formik.setFieldValue('jobParams.s3_path_yn', value);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.jobParams?.s3_path_yn ?? ''}
          className={`form-control ${
            formik.touched.jobParams?.s3_path_yn &&
            formik.errors.jobParams?.s3_path_yn &&
            'is-invalid'
          }`}
        >
          <option value="" disabled>
            Select y path
          </option>
          {[...Array(13).keys()].map((number) => (
            <option key={number} value={number}>
              {number}
            </option>
          ))}
        </select>
        {formik.touched.jobParams?.s3_path_yn &&
          formik.errors.jobParams?.s3_path_yn && (
            <div className="invalid-feedback">
              {formik.errors.jobParams.s3_path_yn}
            </div>
          )}
      </Col>
    </>
  );
};

Inference.propTypes = {
  formik: PropTypes.object,
};

export default Inference;
