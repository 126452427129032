import { Button, Container, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BoxArrowDownLeft } from 'react-bootstrap-icons';
import signOut from './signouthelper';
import { useAuthContext } from '../pages/auth/contexts';

const Navigationbar = () => {
  const [_authState, authDispatch] = useAuthContext();

  return (
    <Navbar
      collapseOnSelect
      key={false}
      expand={false}
      bg="dark"
      variant="dark"
      sticky="top"
    >
      <Container fluid>
        <div>
          <Navbar.Toggle aria-controls="offcanvasNavbar-expand-false" />
          <Navbar.Brand href="">
            <img alt="" src="/logo.png" width="127" height="36" /> Kvasir
          </Navbar.Brand>
        </div>

        <Navbar.Offcanvas
          id="offcanvasNavbar-expand-false"
          aria-labelledby="offcanvasNavbarLabel-expand-false"
          placement="start"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel-expand-false">
              Kvasir
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link eventKey="1" as={Link} to="/jobs">
                New Jobs
              </Nav.Link>
              <Nav.Link eventKey="1" as={Link} to="/jobs_old">
                Old DR Jobs
              </Nav.Link>
              <Button
                variant="danger"
                onClick={async () => {
                  await signOut(authDispatch);
                }}
              >
                <BoxArrowDownLeft />
                Sign Out
              </Button>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default Navigationbar;
