import React from 'react';
import PropTypes from 'prop-types';
import Modal from './Modals/Modal';

const KillJobModal = ({ killJobHandler, jobKillFlag, setJobKillFlag }) => (
  <Modal
    submitHandler={() => {
      killJobHandler(jobKillFlag.jobStateMachineId);
      setJobKillFlag({
        openModal: false,
        jobStateMachineId: null,
      });
    }}
    show={jobKillFlag.openModal}
    onHide={() =>
      setJobKillFlag({
        openModal: false,
        jobStateMachineId: null,
      })
    }
    heading={`Job ID: ${jobKillFlag.jobStateMachineId}`}
    message="Do you want to kill the job?"
  />
);

KillJobModal.propTypes = {
  killJobHandler: PropTypes.func,
  jobKillFlag: PropTypes.shape({
    openModal: PropTypes.bool,
    jobStateMachineId: PropTypes.number,
  }),
  setJobKillFlag: PropTypes.func,
};

export default KillJobModal;
