import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';

const RetryCancelModal = (props) => {
  const { show, heading, message, onHide, submitHandler } = props;
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Cancel</Button>
        <Button
          variant="primary"
          onClick={submitHandler}
          style={{ backgroundColor: 'red', color: 'white' }}
        >
          Force Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

RetryCancelModal.propTypes = {
  show: PropTypes.bool.isRequired,
  heading: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
};

export default RetryCancelModal;
