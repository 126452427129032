import PropTypes from 'prop-types';

import styles from './authentication.module.css';

const SignUpContainer = ({ children }) => (
  <div className={styles.signUpRoot}>
    <div>
      <img src="/logo.png" className={styles.logo} alt="logo" />
    </div>
    <div className={styles.infoCard}>
      <div>{children}</div>
    </div>
  </div>
);

SignUpContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default SignUpContainer;
