/* eslint-disable react/prop-types, react/no-unstable-nested-components */
// import { Fetch } from "react-request";
import React, { useMemo, useState } from 'react';

import { ThreeDotsVertical } from 'react-bootstrap-icons';
import { Row, Container, Nav, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'moment-timezone';

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import TimeChange from '../../components/TimeChange';
import Status from '../../components/Status';

import JobTableOldData from './JobTablesOldData';

import { listOldJobs, killJob } from '../jobs/api';

import ShowDRLogs from './ShowDRJobLogs';

import Modal from '../jobs/Modals/Modal';
import { FilterProvider } from '../Filters/FilterContext';

const statusVal = ({ cell: { value } }) => <Status state={value} />;
const submittedTime = ({ cell: { value } }) => <TimeChange date={value} />;
const endTime = ({ cell: { value } }) =>
  value != null ? <TimeChange date={value} /> : null;

const resultLink = ({ cell: { value } }) => (
  <a href={value} target="_blank" rel="noopener noreferrer">
    Link
  </a>
);

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    className="toggleButtonDropdown"
    style={{ background: 'none', border: 'none' }}
    type="button"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <ThreeDotsVertical />
  </button>
));

const OldJobs = () => {
  const [showLogsFlag, setShowLogsFlag] = useState({
    open: false,
    jobId: null,
    jobParams: {},
  });

  const [jobKillFlag, setJobKillFlag] = useState({
    openModal: false,
    jobStateMachineId: null,
  });

  const [modalInfo, setModalInfo] = useState({
    openModal: false,
    heading: '',
    body: '',
  });

  const queryClient = useQueryClient();
  const [pageNumber, setPageNumber] = useState(1);
  const query = useQuery(
    ['listOldJobs', pageNumber],
    () => listOldJobs(pageNumber),
    {
      keepPreviousData: true,
    }
  );

  const killJobMutation = useMutation(killJob, {
    onSuccess: () => {
      queryClient.invalidateQueries(['listOldJobs', pageNumber]);
    },
  });

  const showLogsHandler = async (row) => {
    setShowLogsFlag({
      open: true,
      jobId: row.id,
      jobParams: row.job_params,
    });
  };

  const closeShowLogs = () => {
    setShowLogsFlag({
      open: false,
      jobId: null,
      jobParams: {},
    });
  };
  const killJobHandler = (jobId) => {
    killJobMutation.mutate({
      jobId,
    });
  };

  const jobColumns = useMemo(
    () => [
      {
        Header: 'Job ID',
        accessor: 'id',
      },
      {
        Header: 'Job Type',
        accessor: 'job_type',
        Cell: ({ value }) => value?.replaceAll('_', ' '),
      },
      {
        Header: 'Job Params',
        accessor: 'job_params',
        Cell: ({ value }) =>
          Object.keys(value).map((item) => (
            <div key={item}>
              <span style={{ fontWeight: 600 }}>{item}</span>: {value[item]}
            </div>
          )),
      },
      {
        Header: 'Status',
        accessor: 'status',
        // Cell: ({ cell: { value } }) => <Status value={value} />,
        Cell: statusVal,
      },
      {
        Header: 'Submitted At',
        accessor: 'submited_time',
        // Cell: ({ cell: { value } }) => <TimeChange date={value} />,
        Cell: submittedTime,
      },
      {
        Header: 'Ended At',
        accessor: 'end_time',
        // Cell: ({ cell: { value } }) =>
        //   value != null ? <TimeChange date={value} /> : null,
        Cell: endTime,
      },
      {
        Header: 'Result',
        accessor: 'job_result.props.compare_link',
        // Cell: ({ cell: { value }, row: { values } }) => (
        //   <a href={value} target="_blank" rel="noopener noreferrer">
        //     Link
        //   </a>
        // ),
        Cell: resultLink,
      },
    ],
    []
  );

  if (query.isLoading) {
    return (
      <div className="loading">
        <img alt="logo" src="/logo.png" />
      </div>
    );
  }

  if (query.isError) {
    return <div>The request did not succeed.</div>;
  }

  if (query.data) {
    return (
      <Container>
        <Row>
          {/* Add a link to navigate to "jobs_old_data" */}
          <Link
            style={{
              backgroundColor: '#ffffff',
              color: '#007bff',
              padding: '10px',
              marginTop: '10px',
              textDecoration: 'none',
            }}
            to="/jobs"
          >
            New Jobs Data
          </Link>
        </Row>

        <Row>
          <FilterProvider>
            <JobTableOldData
              columns={jobColumns}
              data={query.data.list}
              dataUpdatedAt={query.dataUpdatedAt}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              count={query.data.count}
              showLogsHandler={showLogsHandler}
              setJobKillFlag={setJobKillFlag}
            />
          </FilterProvider>
        </Row>
        {showLogsFlag.open && (
          <ShowDRLogs
            show={showLogsFlag.open}
            handleClose={closeShowLogs}
            jobParams={showLogsFlag.jobParams}
            jobId={showLogsFlag.jobId}
          />
        )}

        <Modal
          show={modalInfo.openModal}
          onHide={() =>
            setModalInfo({
              openModal: false,
              heading: '',
              body: '',
            })
          }
          heading={modalInfo.heading}
          message={modalInfo.body}
        />
      </Container>
    );
  }
};

CustomToggle.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

Status.propTypes = {
  value: PropTypes.string,
};

OldJobs.propTypes = {};

export default OldJobs;
