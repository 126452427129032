/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { Col } from 'react-bootstrap';

import PropTypes from 'prop-types';
import COUNTRY_ABBRS from '../../../constants/countries';
import './Template.css';

const DeepResolver = ({ formik }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);

    // Filter the results based on the input value
    const filteredResults = Object.keys(COUNTRY_ABBRS).filter((country) =>
      country.toLowerCase().includes(value.toLowerCase())
    );

    setSearchResults(filteredResults);
  };

  const handleResultClick = (selectedResult) => {
    formik.setFieldValue(
      'jobParams.countryAbbr',
      COUNTRY_ABBRS[selectedResult]
    );
    setSearchTerm(selectedResult);
    setSearchResults([]);
  };

  return (
    <>
      <Col md>
        <input
          type="text"
          name="jobParams.mgrs"
          placeholder="MGRS"
          onChange={(e) => {
            const value = e.target.value || '';
            formik.setFieldValue('jobParams.mgrs', value.toUpperCase());
          }}
          onBlur={formik.handleBlur}
          value={formik.values.jobParams?.mgrs ?? ''}
          className={`form-control ${
            formik.touched.jobParams?.mgrs &&
            formik.errors.jobParams?.mgrs &&
            'is-invalid'
          }`}
        />
        {formik.touched.jobParams?.mgrs && formik.errors.jobParams?.mgrs && (
          <div className="invalid-feedback">{formik.errors.jobParams.mgrs}</div>
        )}
      </Col>

      <Col md>
        <input
          type="text"
          name="jobParams.date"
          placeholder="Date (YYYY-MM-DD)"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.jobParams?.date ?? ''}
          className={`form-control ${
            formik.touched.jobParams?.date &&
            formik.errors.jobParams?.date &&
            'is-invalid'
          }`}
        />
        {formik.touched.jobParams?.date && formik.errors.jobParams?.date && (
          <div className="invalid-feedback">{formik.errors.jobParams.date}</div>
        )}
      </Col>

      <Col md>
        <input
          type="text"
          name="jobParams.tags"
          placeholder="Tags"
          onChange={(e) => {
            const value = e.target.value || '';
            formik.setFieldValue('jobParams.tags', value.replace(/\s/g, '_'));
          }}
          onBlur={formik.handleBlur}
          value={formik.values.jobParams?.tags ?? ''}
          className={`form-control ${
            formik.touched.jobParams?.tags &&
            formik.errors.jobParams?.tags &&
            'is-invalid'
          }`}
        />
        {formik.touched.jobParams?.tags && formik.errors.jobParams?.tags && (
          <div className="invalid-feedback">{formik.errors.jobParams.tags}</div>
        )}
      </Col>
      <Col md>
        <input
          type="text"
          name="jobParams.countryAbbr"
          placeholder="Country Code"
          onChange={handleInputChange}
          onBlur={formik.handleBlur}
          value={searchTerm}
          className={`form-control ${
            formik.touched.jobParams?.countryAbbr &&
            formik.errors.jobParams?.countryAbbr &&
            'is-invalid'
          }`}
        />
        {searchTerm && searchResults.length > 0 && (
          <div className="search-results">
            <ul>
              {searchResults.map((result) => (
                <li key={result} className="search-result-item">
                  <button
                    onClick={() => handleResultClick(result)}
                    type="button"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleResultClick(result);
                      }
                    }}
                  >
                    {result}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}

        {formik.touched.jobParams?.countryAbbr &&
          formik.errors.jobParams?.countryAbbr && (
            <div className="invalid-feedback">
              {formik.errors.jobParams.countryAbbr}
            </div>
          )}
      </Col>
    </>
  );
};

DeepResolver.propTypes = {
  formik: PropTypes.object,
};

export default DeepResolver;
