import React, { useEffect } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';

import { useInfiniteQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { showJobLogsOld } from '../jobs/api';
import './showDrJobLogs.css';
import TimeChange from '../../components/TimeChange';

const ShowDRLogs = ({ show, handleClose, jobParams, jobId }) => {
  // const [nextToken, setNextToken] = useState('')
  const { ref, inView } = useInView();

  const query = useInfiniteQuery({
    queryKey: ['jobLogs', jobId],
    queryFn: ({ pageParam }) => showJobLogsOld({ jobId, nextToken: pageParam }),
    getNextPageParam: (lastPage) => {
      // console.log({ lastPage }, lastPage.next_forward_token);
      console.log('');
      return lastPage.events.length ? lastPage.next_forward_token : undefined;
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (inView) {
      query.fetchNextPage();
    }
  }, [inView]);

  return (
    <Modal fullscreen show={show} onHide={handleClose}>
      <Modal.Header closeButton scrollable>
        <Modal.Title>
          Job logs for: {jobParams.mgrs_t} {jobParams.date}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {query.isLoading ? (
          <div>Loading...</div>
        ) : query.data ? (
          <Table stripped bordered hover>
            <colgroup>
              <col span="1" style={{ width: '15%' }} />
              <col span="1" style={{ width: '70%;' }} />
            </colgroup>
            <thead>
              <tr>
                <th className="logs-timestamp">Time</th>
                <th>Log</th>
              </tr>
            </thead>
            <tbody>
              {query.data.pages.map((group) => (
                <React.Fragment key={group.id}>
                  {group.events.map(({ timestamp, message }) => (
                    <tr>
                      <td className="logs-timestamp">
                        <TimeChange date={timestamp} />
                      </td>
                      <td>{message}</td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        ) : (
          <div>Error</div>
        )}

        <Button
          style={{ marginLeft: '50%' }}
          ref={ref}
          disabled={!query.hasNextPage || query.isFetchingNextPage}
          variant="link"
          onClick={() => query.fetchNextPage()}
        >
          {query.isFetchingNextPage
            ? 'Loading More..'
            : query.hasNextPage
            ? 'Load More'
            : 'Nothing more to load'}
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ShowDRLogs.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  jobParams: PropTypes.objectOf(PropTypes.object.isRequired),
  jobId: PropTypes.number,
};

export default ShowDRLogs;
