import { Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import Navigationbar from '../../components/Navbar';

const AuthRoute = ({ authUser }) =>
  authUser ? (
    <>
      <Navigationbar />
      <Outlet />
    </>
  ) : (
    <Navigate to="/sign-in" />
  );

AuthRoute.propTypes = {
  authUser: PropTypes.objectOf(PropTypes.any.isRequired),
};
export default AuthRoute;
