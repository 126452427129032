import axios from 'axios';
import { get, post, patch } from '../../utils/API';

export const listJobs = async (page) => {
  const response = await get(`/jobs?page=${page}`);
  // const response = await axios.get(
  //   "https://development.kvasir.digifarm.io/jobs"
  // );

  console.log('FROM API TO DEBUG:', response);
  return response;
};

export const listOldJobs = async (page) => {
  const response = await get(`/jobs_old?page=${page}`);
  // const response = await axios.get(
  //   "https://development.kvasir.digifarm.io/jobs"
  // );
  return response;
};

export const createJob = async ({ jobType, jobParams }) => {
  const updatedJobParams = {
    ...jobParams,

    countryAbbr: jobParams.countryAbbr || '',
  };

  const updatedInfJobParams = {
    ...jobParams,

    images_list: jobParams.images_list || '',
    need_ortho: jobParams.need_ortho || 'false',
  };
  const params = {
    job_type: jobType,
    job_params: jobType === 'DRV3' ? updatedJobParams : updatedInfJobParams,
  };

  const res = await post('/jobs', params);

  return res;
};

export const killJob = async ({ jobId }) => {
  const url = `/jobs/${jobId}/kill`;
  const response = await patch(url);
  return response;
};

export const showJobLogs = async ({ jobId, nextToken }) => {
  const url = `/jobs/${jobId}/logs`;
  const params = {};

  if (nextToken) {
    params.next_token = nextToken;
  }

  const response = await get(url, params);

  return response;
};

export const showJobLogsOld = async ({ jobId, nextToken }) => {
  const url = `/jobs_old/${jobId}/logs`;
  const params = {};

  if (nextToken) {
    params.next_token = nextToken;
  }

  const response = await get(url, params);

  return response;
};

export const L2ADateCheck = async (date, mgrs) => {
  const modifiedDate = date.replaceAll('-', '');
  // console.log(modifiedDate);
  // const year = modifiedDate.slice(0, 4);
  // const month = modifiedDate.slice(4, 6);

  const startDate = new Date(date);
  const endDate = new Date(date);

  startDate.setMonth(startDate.getMonth() - 6);
  endDate.setMonth(endDate.getMonth() + 6);

  let res = true;

  const config = {
    method: 'get',
    url: `https://zi653e6m2b.execute-api.eu-central-1.amazonaws.com/dev/?mgrs=${mgrs.toUpperCase()}&start_date=${startDate
      .toISOString()
      .slice(0, 10)}&end_date=${endDate.toISOString().slice(0, 10)}`,
    headers: {},
  };
  try {
    const response = await axios(config);

    const ans = JSON.stringify(response.data);

    const datesObject = JSON.parse(ans);

    res =
      datesObject.product_count > 0 &&
      datesObject.all.url.includes(modifiedDate);

    return res;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const OrthoFileCheck = async (country, tile, orthoVersion) => {
  const url = `/jobs/inference/ortho_prep_version_and_file_check?country=${country}&tile=${tile}&DR_TILES_VER=${orthoVersion}`;

  const response = await get(url);

  return response;
};

export const DRfilesCheck = async (country, tile, fileType) => {
  const url = `/jobs/ortho/get_dr_tiles?country=${country}&tile=${tile}&file_type=${fileType}`;

  const response = await get(url);

  return response;
};
